<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-center justify-content-start px-0 position-relative fluid w-100"
    >
      <!-- Trial Banner -->
      <b-alert
        style="
          background-color: rgba(11, 155, 125, 0.5);
          color: black;
          border-radius: 10px;
        "
        dismissible
        :show="!showBanner"
        @dismissed="dismissBanner"
      >
        <div class="d-flex align-items-center">
          <div>
            Please note that your Figa account with free trial will expire in
            {{ this.TrialData }} days.
          </div>
        </div>
      </b-alert>
      <!-- cash flow row -->
      <b-row
        class="d-flex flex-row align-items-center justify-content-center cash-flow-row w-100"
        no-gutters
      >
        <b-card class="shadow-sm border-0 w-100">
          <b-row
            class="table-container-card d-flex flex-row w-100 text-message"
            no-gutters
          >
            <b-col cols="8" lg="8" md="12" sm="12" class="pr-5 lineGraph">
              <b-row cols="12">
                <b-col
                  cols="6"
                  class="align-items-center justify-content-start d-flex"
                >
                  <h4 class="p-3">Cash Flow</h4></b-col
                >
                <b-col
                  cols="6"
                  class="align-items-center justify-content-end d-flex"
                >
                  <b-link
                    class="report-text text-main-green"
                    href="/reports/cash-flow"
                    >View Report
                  </b-link>
                </b-col>
                <!-- <b-col
                  cols="12"
                  class="align-items-center justify-content-end d-flex"
                >
                  <b-button variant="outline-main-green" pill class="ml-1">
                    Last 24 months
                    <b-icon icon="calendar2-week" variant="main-green"></b-icon
                  ></b-button>
                </b-col> -->
              </b-row>

              <LineGraph :year="fascal" :fiscal_year="save_fiscal_year" />
            </b-col>
            <b-col cols="4" lg="4" md="12" sm="12" class="p-3 lineGraph">
              <h4
                class="d-flex flex-column flex-sm-row align-items-center justify-content-start justify-content-sm-start mb-5"
              >
                Net Income
              </h4>
              <b-row class="mt-4">
                <b-col cols="6" class="mt-3">Fiscal Year</b-col>
                <b-col
                  cols="6"
                  class="mt-3 d-flex flex-column flex-sm-row align-items-end justify-content-end justify-content-sm-end"
                >
                  <div class="dropdown-container">
                    <b-form-select
                      groupId="type-group"
                      id="type-group-input"
                      class="yearselect"
                      :whiteBG="false"
                      :options="yearoption"
                      form="login-form"
                      v-model="fascal"
                    >
                    </b-form-select>
                  </div>
                </b-col>
              </b-row>
              <div class="mt-5">
                <h2
                  class="d-flex justify-content-end justify-content-sm-end netincome-text"
                >
                  {{ currency }} {{ numberFormat(net_income.net) }}
                </h2>
                <h6
                  class="d-flex justify-content-end justify-content-sm-end mb-4 heading-text"
                >
                  Net Income
                </h6>
                <h4
                  class="d-flex justify-content-end justify-content-sm-end income-text"
                >
                  {{ currency }} {{ numberFormat(net_income.income) }}
                </h4>
                <h6
                  class="d-flex justify-content-end justify-content-sm-end mb-4 heading-text"
                >
                  Income
                </h6>
                <h4
                  class="d-flex justify-content-end justify-content-sm-end expenses-text"
                >
                  {{ currency }} {{ numberFormat(net_income.expense) }}
                </h4>
                <h6
                  class="d-flex justify-content-end justify-content-sm-end heading-text"
                >
                  Expenses
                </h6>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="table-container-card d-flex flex-row w-100 text-message"
            no-gutters
          >
            <b-col cols="8" lg="8" md="12" sm="12" class="p-3 lineGraph">
              <b-row cols="12">
                <b-col
                  cols="6"
                  class="align-items-center justify-content-start d-flex"
                >
                  <h4 class="p-2">Profit & Loss</h4></b-col
                >
                <b-col
                  cols="6"
                  class="align-items-center justify-content-end d-flex"
                >
                  <b-link
                    class="report-text text-main-green"
                    href="/reports/profit-and-loss"
                    >View Report
                  </b-link>
                </b-col>
                <!-- <b-col
                  cols="12"
                  class="align-items-center justify-content-end d-flex"
                >
                  <b-button variant="outline-main-green" pill class="ml-1">
                    Last 24 months
                    <b-icon icon="calendar2-week" variant="main-green"></b-icon
                  ></b-button>
                </b-col> -->
              </b-row>
              <BarGraph :year="fascal" :fiscal_year="save_fiscal_year" />
            </b-col>
            <b-col cols="4" lg="4" md="12" sm="12" xs="12" class="lineGraph">
              <h4 class="p-4">Expenses Breakdown</h4>
              <DonutGraph :year="fascal" />
            </b-col>
          </b-row>

          <h4 class="pl-3 pt-3">Top Invoices</h4>
          <b-row cols="12" class="lineGraph">
            <b-col cols="6" class="p-3">
              <div class="p-3">
                <h5 class="table-text">Payables</h5>
                <hr class="table-line" />
              </div>
              <b-table
                class="pl-3"
                hover
                :items="items"
                width="100%"
                :borderless="true"
                :no-border-collapse="true"
                thead-tr-class="d-none"
              ></b-table>
            </b-col>
            <b-col cols="6" class="p-3">
              <div class="p-3">
                <h5 class="table-text">Sales</h5>
                <hr class="table-line" />
              </div>
              <b-table
                hover
                :items="items2"
                width="100%"
                :borderless="true"
                thead-tr-class="d-none"
              ></b-table>
            </b-col>
          </b-row>
          <h4 class="pl-3 pt-3">Payables & Receivables - Ageing</h4>
          <b-row cols="12" class="">
            <b-col cols="6" class="p-3">
              <div class="p-3">
                <h5 class="table-text">Sales</h5>
                <hr class="table-line" />
              </div>
              <b-table
                class="pl-3"
                hover
                :items="items3"
                width="100%"
                :borderless="true"
                :no-border-collapse="true"
                thead-tr-class="d-none"
              ></b-table>
            </b-col>
            <b-col cols="6">
              <div class="p-3 mt-3">
                <h5 class="table-text">Bills</h5>
                <hr class="table-line" />
              </div>
              <b-table
                hover
                :items="items4"
                width="100%"
                :borderless="true"
                thead-tr-class="d-none"
              ></b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!--  <b-col
        class="d-flex flex-row align-self-end align-items-center justify-content-center mt-4"
      >
        <b-img
          class="logo-footer-img"
          src="@/assets/logo_footer_black_vault.png"
          contain
          height="60px"
        ></b-img>
      </b-col>-->
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import LineGraph from "@/components/Dashboard/LineGraph";
import BarGraph from "@/components/Dashboard/BarGraph";
import DonutGraph from "@/components/Dashboard/DonutGraph";
import {
  NetIncome,
  PayableBills,
  PayableInvoice,
  FiscalYearRange,
} from "@/services/analytics.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import { dashboardtable } from "@/services/invoice.service";
import { formatNumbers } from "@/util/formatNumbers.js";
import { GetPackageTrailDays } from "@/services/settingsbusiness.service";
import generateBusinessYears from "@/util/createBusinessYears.js";

//import FormSelect from "@/components/Form/FormSelect";

export default {
  name: "Dashboard",
  components: {
    HomeLayout,
    LineGraph,
    BarGraph,
    DonutGraph,
    //  FormSelect,
  },
  data() {
    const { currentYear } = generateBusinessYears();
    return {
      showBanner: true,
      TrialData: null,
      one: 0.0,
      two: 0.0,
      three: 0.0,
      four: 0.0,
      items: [
        // { place:"#1",due: "INV-0597", amount: "NZD 4000.00" },
        // { place:"#2",due: "INV-0435", amount: "NZD 5500.00" },
        // {place:"#3", due: "INV-6123", amount: "NZD 5500.00" },
      ],
      items2: [
        // {  place:"#1",due: "INV-6123", amount: "NZD 5500.00" },
        // {  place:"#2",due: "INV-6123", amount: "NZD 5500.00" },
        // {  place:"#3",due: "INV-6123", amount: "NZD 5500.00" },
      ],
      items3: [
        // { due: "Coming due", amount: " 0.00" },
        //   { due: "1-30 days overdue", amount: " 0.00" },
        //   { due: "31-60 days overdue", amount: " 0.00" },
        //   { due: "61-90 days overdue", amount: " 0.00" },
        //   { due: "> 90 days overdue", amount: this.currency + " 0.00" },
      ],
      items4: [
        // { due: "Coming due", amount: " 0.00" },
        //   { due: "1-30 days overdue", amount: " 0.00" },
        //   { due: "31-60 days overdue", amount: " 0.00" },
        //   { due: "61-90 days overdue", amount: " 0.00" },
        //   { due: "> 90 days overdue", amount: this.currency + " 0.00" },
      ],

      currency: localStorage.getItem("CURRENCY"),
      yearoption: null,
      net_income: {
        net: 0.0,
        income: 0.0,
        expense: 0.0,
      },
      fascal: currentYear,
      save_fiscal_year: null,
    };
  },
  watch: {
    fascal(val) {
      this.fascal = val;
      this.get_net_profit();
    },
  },
  async mounted() {
    await this.init();
    await this.get_trialendDate();
    localStorage.removeItem("temp_pass");
    localStorage.removeItem("temp_email");
  },
  methods: {
    startTrial() {
      // Implement your logic to start the trial
      console.log("Starting trial...");
    },
    dismissBanner() {
      this.showBanner = !this.showBanner;
    },
    numberFormat(val) {
      // return formatNumbers(val);
      if (val < 0) {
        return `(${formatNumbers(Math.abs(val))})`;
      } else {
        return formatNumbers(val);
      }
    },
    async init() {
      let { data } = await GetCurrentWorkspace();
      this.save_fiscal_year = data.fiscal_year;
      let dataDashboard = await dashboardtable();
      const data123 = dataDashboard.data.results.customerInvoices.map(
        (item, index) => {
          return {
            id: index + 1,
            invoice_number: item.invoice_number,
            total_amount:
              this.currency + " " + this.numberFormat(item.total_amount),
          };
        }
      );
      this.items2 = data123;
      const data12 = dataDashboard.data.results.supplierInvoices.map(
        (item, index) => {
          return {
            id: index + 1,
            invoice_number: item.invoice_number,
            total_amount:
              this.currency + " " + this.numberFormat(item.total_amount),
          };
        }
      );
      this.items = data12;
      localStorage.setItem("CURRENCY", data.currency);
      this.currency = localStorage.getItem("CURRENCY");
      await this.get_net_profit();
      await this.get_payable_invoice();
      await this.get_payable_bills();
    },
    async get_trialendDate() {
      try {
        let { data } = await GetPackageTrailDays();
        const {
          data: { fiscal_years },
        } = await FiscalYearRange();
        this.yearoption = fiscal_years;

        this.showBanner = data.purchase;
        this.TrialData = data.daysLeft;
      } catch (error) {
        console.log(error);
      }
    },
    async get_net_profit() {
      await NetIncome(this.fascal).then((res) => {
        this.net_income.income = res.data.analysis.incomes;
        this.net_income.expense = res.data.analysis.expenses;
        this.net_income.net = this.net_income.income - this.net_income.expense;
      });
    },
    async get_payable_invoice() {
      await PayableInvoice().then((res) => {
        console.log(res);
        this.items3 = [
          {
            due: "Coming due",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.five).toFixed(2),
          },
          {
            due: "1-30 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.one).toFixed(2),
          },
          {
            due: "31-60 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.two).toFixed(2),
          },
          {
            due: "61-90 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.three).toFixed(2),
          },
          {
            due: "> 90 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.four).toFixed(2),
          },
        ];
        console.log(this.items);
      });
    },
    async get_payable_bills() {
      await PayableBills().then((res) => {
        this.items4 = [
          {
            due: "Coming due",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.five).toFixed(2),
          },
          {
            due: "1-30 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.one).toFixed(2),
          },
          {
            due: "31-60 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.two).toFixed(2),
          },
          {
            due: "61-90 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.three).toFixed(2),
          },
          {
            due: "> 90 days overdue",
            amount:
              this.currency +
              " " +
              parseFloat(res.data.analysis.four).toFixed(2),
          },
        ];
      });
    },
  },
};
</script>

<style scoped>
/* table card */
.cash-flow-row {
  height: 100%;
}

.cash-flow-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 0;
  border: 1px solid var(--light);
}

.text-message {
  font-size: 18px;
}

.report-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Mid Blue */

  color: #1c72ff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.netincome-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #0012df;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.income-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #146a38;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.heading-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #828282;
  flex: none;
  order: 1;
  flex-grow: 0;
  line-height: 0.5;
}

.expenses-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #eb0e0e;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.table-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.dropdown-container .yearselect {
  color: black;
  border: none !important;
  box-shadow: none;
  text-align: right;
  width: fit-content;
}

.dropdown-menu {
  position: absolute;
  right: 0;
}
hr.table-line {
  border: 2px solid #e0e0e0;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .dropdown-container {
    margin-left: 160px;
  }
  .lineGraph {
    width: 100%;
    max-width: 100vw;
    min-width: 100%;
    position: relative;
  }
}
</style>
