function generateBusinessYears(){
    const yearoptions = [
        { value: 2019, text: "2019" },
        { value: 2020, text: "2020" },
        { value: 2021, text: "2021" },
        { value: 2022, text: "2022" },
        { value: 2023, text: "2023" },
      ];
      const currentYear = new Date().getFullYear();
  
      const lastYear = yearoptions.at(4).value
  
      const gap = currentYear-lastYear
  
      for(let i=0;i<gap;i++){
         yearoptions.push({
          value: lastYear+(i+1),
          text: (lastYear+(i+1)).toString(),
        })
      }

   return {yearoptions,currentYear}   
}

export default generateBusinessYears